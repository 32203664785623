<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="filteredCountedProducts && countData">
      <trac-back-button>{{ countData.from }}</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl capitalize">
            {{ countData.name }}
          </h3>
        </div>
        <div class="flex-col">
          <trac-button @button-clicked="saveForLater" class="uppercase"
            >save for later</trac-button
          >
        </div>
      </div>

      <div class="flex flex-row h-full gap-5">
        <div class="px-8 py-8 w-6/12 bg-white rounded-lg h-auto big-shadow">
          <div>
            <!-- <button
              @click="scannerCtrl = !scannerCtrl"
              class="font-semibold text-sm text-primaryBlue"
            >
              {{ scannerCtrl ? "Hide Scanner" : "Show Scanner" }}
            </button> -->
          </div>
          <div class="h-128 flex flex-col items-center">
            <!-- v-if="!QRReaderActive" -->
            <span class="text-sm text-primaryGray mx-auto">
              <trac-toggle
                class="mb-2"
                :active="scannerCtrl"
                @newval="scannerCtrl = !$event"
              >
                {{ scannerCtrl ? 'Open' : 'Close' }}
              </trac-toggle>
            </span>

            <StreamBarcodeReader
              class="mt-4"
              v-if="scannerCtrl"
              v-show="QRReaderActive"
              @decode="onDecode"
              @loaded="onLoaded"
            ></StreamBarcodeReader>
            <div class="flex justify-center items-center w-full" v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                class="mt-32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#909090af"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M2 2l19.8 19.8M15 15.7V17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h.3m5.4 0H13a2 2 0 0 1 2 2v3.3l1 1L22 7v10"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="px-8 py-8 w-6/12 bg-white rounded-lg h-full overflow-hidden big-shadow"
        >
          <div class="">
            <p class="text-xs text-gray-500 text-right">
              <span class="text-red-600">*</span>
              Only products that are stock managed are displayed.
            </p>
          </div>
          <div class="bg-white border mt-5 rounded-lg overflow-auto">
            <table class="w-full rounded-lg table-auto overflow-auto">
              <thead class="bg-blue-100">
                <tr class="text-center">
                  <th class="text-left p-4 text-sm font-semibold uppercase">
                    products
                  </th>
                  <th class="p-4 text-sm font-semibold uppercase text-right">
                    counted
                  </th>
                  <!-- <th class="text-left p-4 text-sm font-semibold uppercase">
                    expected
                  </th> -->
                </tr>
              </thead>
              <tbody
                class="overflow-auto h-60"
                v-if="productsToCount.length > 0"
              >
                <tr
                  v-for="(product, index) in productsToCount"
                  :key="index"
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                >
                  <template v-if="!product.hasVariant">
                    <td class="p-4 text-xs font-medium">
                      {{ product.title || product.name }}
                    </td>
                    <td class="p-4 text-xs font-medium">
                      <div class="flex flex-row gap-3 justify-end items-center">
                        <img
                          class="cursor-pointer"
                          @click="handleQty('increase', product)"
                          src="../../../assets/svg/add.svg"
                        />
                        <input
                          class="w-2/12 p-2 text-center"
                          type="number"
                          v-model.number="product.counted"
                        />
                        <img
                          class="cursor-pointer"
                          @click="handleQty('decrease', product)"
                          src="../../../assets/svg/minus.svg"
                        />
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <div v-for="(prod, id) in product.variations" :key="id">
                      <td class="p-4 text-xs font-medium">
                        {{ prod.title || prod.name }}
                      </td>
                      <td class="p-4 text-xs font-medium">
                        <div
                          class="flex flex-row gap-3 justify-end items-center"
                        >
                          <img
                            class="cursor-pointer"
                            @click="handleQty('increase', prod)"
                            src="../../../assets/svg/add.svg"
                          />
                          <input
                            class="w-2/12 p-2 text-center"
                            type="number"
                            v-model.number="prod.counted"
                          />
                          <img
                            class="cursor-pointer"
                            @click="handleQty('decrease', prod)"
                            src="../../../assets/svg/minus.svg"
                          />
                        </div>
                      </td>
                    </div>
                  </template>
                  <!-- <td class="p-4 text-xs font-medium">
                    {{ product.expected }}
                  </td> -->
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3">
                    <trac-center-data>
                      <div class="h-40 flex items-center text-xs text-gray-600">
                        No product available for count.
                      </div>
                    </trac-center-data>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-16">
            <trac-button
              :disabled="productsToCount.length === 0"
              @button-clicked="sendCount"
              class="uppercase w-full"
              >Submit Count</trac-button
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from '../../../browser-db-config/localStorage';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import { GENERATE_RANDOM_ID } from '../../../browser-db-config/utilityMethods';
import { eventBus } from './../../../main';

export default {
  name: 'SubmitCount',
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      isLoading: false,
      countData: null,
      selectedCategoryIDs: null,
      products: null,
      filteredCountedProducts: null,
      QRReaderActive: false,
      scannerCtrl: true,
    };
  },
  async created() {
    this.isLoading = true;
    this.countData = GET_LOCAL_DB_DATA('stock-count-data');

    if (!this.countData) {
      this.$router.back();
    }
    if (this.countData) {
      this.countData.counted_products = this.countData.saved_counted_details
        ? this.countData.saved_counted_details.Products
        : [];
      this.selectedCategoryIDs = this.countData.selected_categories.map(
        (category) => category?.id || category.name
      );
    }

    await this.fetchAllProducts();
    this.isLoading = false;
  },
  computed: {
    productsToCount() {
      const productsToCountData = [];
      this.filteredCountedProducts.forEach((product) => {
        if (
          product.hasVariant &&
          this.selectedCategoryIDs.includes(
            product.categories?.id || product.categories?.name
          )
        ) {
          productsToCountData.push(...product.variations);
        } else if (
          this.selectedCategoryIDs.includes(
            product.categories?.id || product.categories?.name
          )
        ) {
          productsToCountData.push(product);
        }
      });

      return productsToCountData;
      // this.selectedCategoryIDs;
      // ? this.products.filter((product) =>
      //     product.variation ? product.variations.filter(variant => this.selectedCategoryIDs.includes) : this.selectedCategoryIDs.includes(product.category)
      //   )
      // : [];
    },
  },
  methods: {
    onLoaded(result) {
      this.QRReaderActive = true;
    },
    runCount() {
      this.QRReaderActive = false;
    },
    onDecode(result) {
      // (result);
    },
    saveForLater() {
      let savedCounts = GET_LOCAL_DB_DATA('save_for_later_prod') || [];

      if (this.countData.saved_counted_details) {
        savedCounts = savedCounts.filter(
          (count) =>
            count.sub_id !== this.countData.saved_counted_details.sub_id
        );
      }

      savedCounts.push({
        countData: this.countData,
        sub_id: GENERATE_RANDOM_ID(),
        name: this.countData.name,
        date: new Date().toISOString(),
        paused: true,
        location: this.countData.selectedStore.name,
        Products: this.productsToCount.map((prod) => {
          return {
            ...prod,
            _id: prod._id || prod.id,
            name: prod.title,
            counted: prod.counted,
            reason: prod.reason || '',
          };
        }),
      });

      SAVE_LOCAL_DB_DATA('save_for_later_prod', savedCounts);
      DELETE_LOCAL_DB_DATA('submited-stock-count');
      DELETE_LOCAL_DB_DATA('stock-count-data');
      this.$router.push({ name: 'StockCount' });
    },
    async fetchAllProducts() {
      const res = await this.$store.dispatch('FETCH_PRODUCTS_V2', {
        params: { limit: 1000 },
      });

      if (res.status) {
        this.products = res.data?.products || [];
        if (this.countData.from === 'Count Details') {
          const countedProducts = this.countData.counted_products || [];
          this.filteredCountedProducts = countedProducts.filter(
            (prod) =>
              prod.manageStock || prod?.variations?.some((v) => v.manageStock)
          );
          return;
        }
        this.products = this.products.filter(
          (prod) =>
            prod.manageStock || prod?.variations?.some((v) => v.manageStock)
        );

        this.products = this.products.map((product) => {
          if (product.hasVariant) {
            return {
              ...product,
              variations: product.variations.map((variant) => {
                let item;
                this.countData.counted_products.forEach((p) => {
                  if (p.id === variant.id) {
                    item = {
                      ...variant,
                      counted: p.counted,
                      category: product.categories,
                      parent_product_id: product.id,
                      is_variant: product.hasVariant,
                      variant_id: variant.id,
                      p_id: product.id,
                    };
                  } else if (p.id !== variant.id && !item) {
                    item = {
                      ...variant,
                      counted: 0,
                      category: product.categories,
                      parent_product_id: product.id,
                      is_variant: product.hasVariant,
                      variant_id: variant.id,
                      p_id: product.id,
                    };
                  }
                });
                return (
                  item || {
                    ...variant,
                    counted: 0,
                    category: product.categories,
                    parent_product_id: product.id,
                    is_variant: product.is_variant,
                    variant_id: variant.id,
                    p_id: product.id,
                  }
                );
              }),
            };
          } else {
            let item;
            this.countData.counted_products.forEach((p) => {
              if (p._id === product._id) {
                item = {
                  ...product,
                  counted: p.counted,
                  parent_product_id: product._id,
                  is_variant: !!product.is_variant,
                };
              } else if (p._id !== product._id && !item) {
                item = {
                  ...product,
                  counted: 0,
                  parent_product_id: product._id,
                  is_variant: !!product.is_variant,
                };
              }
            });
            return (
              item || {
                ...product,
                counted: 0,
                parent_product_id: product.id,
                is_variant: !!product.is_variant,
              }
            );
          }
        });

        this.filteredCountedProducts = this.products;
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit('trac-alert', {
          message: res.message || 'Error: Error with network.',
        });
      }
    },
    getProductSpecificStore(prod) {
      return (
        prod.store_stock.find(
          (ss) => ss.store_id === this.countData.selectedStore._id
        ) || {}
      );
    },
    handleQty(type, product) {
      this.filteredCountedProducts.forEach((prod) => {
        if (prod.hasVariant) {
          prod.variations.forEach((variant) => {
            if (type === 'increase' && variant.id === product.id) {
              variant.counted += 1;
            } else if (type === 'decrease' && variant.id === product.id) {
              if (variant.counted > 1) {
                variant.counted -= 1;
              }
            }
          });
        } else {
          if (type === 'increase' && prod.id === product.id) {
            prod.counted += 1;
          } else if (type === 'decrease' && prod.id === product.id) {
            if (prod.counted > 1) {
              prod.counted -= 1;
            }
          }
        }
      });
    },
    async sendCount() {
      if (this.countData.saved_counted_details) {
        SAVE_LOCAL_DB_DATA(
          'submited-saved-stock-count-details',
          this.countData.saved_counted_details
        );
      }

      SAVE_LOCAL_DB_DATA('submited-stock-count', this.productsToCount);
      this.$router.push({ name: 'AcceptCount' });
    },
  },
};
</script>

<style scoped></style>
